// IconButton.js
import React from "react";
import styles from "./IconButton.module.css";

const IconButton = ({
  icon: Icon,
  backgroundColor = "#007bff",
  iconColor = "#fff",
  size = "24px",
  handleButtonClick,
  glow = false,
  isLoading = false,
  disabled = false,
}) => {
  return (
    <button
      onClick={handleButtonClick}
      className={`${styles.iconButton} ${isLoading ? styles.loading : ""}`}
      style={{
        backgroundColor,
        color: iconColor,
        boxShadow: glow && `0 0 3px 2px ${backgroundColor}`,
      }}
      disabled={disabled || isLoading}
    >
      <div className={styles.content}>
        {isLoading ? (
          <div 
            className={styles.spinner}
            style={{ 
              borderColor: iconColor,
              borderTopColor: 'transparent',
              width: size,
              height: size
            }}
          />
        ) : (
          Icon && <Icon size={size} />
        )}
      </div>
    </button>
  );
};

export default IconButton;