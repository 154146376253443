import React from 'react';
import styles from './CustomInput.module.css';

const CustomInput = ({ value, onChange, placeholder = "Enter title" }) => {
  return (
    <input
      type="text"
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      className={styles.input}
    />
  );
};

export default CustomInput