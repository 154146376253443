import { PATHS } from "./paths";
import Home from "../pages/Home";
import Login from "../pages/Login";
import Dashboard from "../pages/Dash";
import Feedback from "../pages/Feedback";
import FeedbackFile from "../pages/Feedback/[filename]";
import FeedbackGuest from "../pages/Feedback/[filename]/[guestId]";
import Settings from "../pages/Settings";
import Account from "../pages/Account";
import Help from "../pages/Help";
import DashboardLayout from "../layout/DashboardLayout";

export const publicRoutes = [
  {
    path: PATHS.PUBLIC.HOME,
    element: Home,
  },
  {
    path: PATHS.PUBLIC.LOGIN,
    element: Login,
  },
];

export const protectedRoutes = [
  {
    path: PATHS.PROTECTED.DASHBOARD,
    element: Dashboard,
    layout: DashboardLayout,
  },
  {
    path: PATHS.PROTECTED.FEEDBACK,
    element: Feedback,
    layout: DashboardLayout,
  },
  {
    path: PATHS.PROTECTED.FEEDBACK_FILE,
    element: FeedbackFile,
    layout: DashboardLayout,
  },
  {
    path: PATHS.PROTECTED.FEEDBACK_GUEST,
    element: FeedbackGuest,
    layout: DashboardLayout,
  },
  {
    path: PATHS.PROTECTED.SETTINGS,
    element: Settings,
    layout: DashboardLayout,
  },
  {
    path: PATHS.PROTECTED.ACCOUNT,
    element: Account,
    layout: DashboardLayout,
  },
  {
    path: PATHS.PROTECTED.HELP,
    element: Help,
    layout: DashboardLayout,
  },
];
export const isDashboardPath = (path) => {
    // Get all protected paths
    const protectedPaths = Object.values(PATHS.PROTECTED);
    
    // First check exact matches
    if (protectedPaths.includes(path)) {
      return true;
    }
  
    // Then check for dynamic routes
    return protectedPaths.some(protectedPath => {
      // Convert route pattern to regex
      // Replace :parameter with any character match
      const pathRegex = new RegExp(
        '^' + protectedPath.replace(/:\w+/g, '[^/]+') + '$'
      );
      return pathRegex.test(path);
    });
  };
