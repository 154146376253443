import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Navbar.module.css";
import logo from "../../assets/logo.svg";
import CustomButton from "../CustomButton";
import { useAuth } from "../../context/AuthContext";
import { NAVBAR_TEXT } from "../../constants/constant";
import { PATHS } from "../../routes/paths";

const Navbar = () => {
  const { isAuthenticated, loading, document } = useAuth();
  const navigate = useNavigate();
  const handleButtonClick = (location) => {
    navigate(location);
  };
  return (
    <nav className={`${styles.navbar} container-95`}>
      <div
        className={styles.logo}
        onClick={() => handleButtonClick(PATHS.PUBLIC.HOME)}
      >
        <img src={logo} alt="Logo" />
      </div>
      <p className={styles.middleText}>
        {NAVBAR_TEXT.welcomeMessage} {document && document.title}
      </p>

      {loading ? (
        "loading..."
      ) : isAuthenticated ? (
        <CustomButton
          handleButtonClick={() => handleButtonClick(PATHS.PROTECTED.DASHBOARD)}
          text="Dashboard"
          backgroundColor="#0D141C"
          textColor="white"
          minWidth="137px"
          height="50px"
          radius="25px"
          glow={false}
        />
      ) : (
        <CustomButton
          handleButtonClick={() => handleButtonClick(PATHS.PUBLIC.LOGIN)}
          text={NAVBAR_TEXT.buttonText}
          backgroundColor="#0D141C"
          textColor="white"
          minWidth="137px"
          height="50px"
          radius="25px"
          glow={false}
        />
      )}
    </nav>
  );
};

export default Navbar;
