// Built-in/Third-party modules
import React, { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import { IoMdPerson } from "react-icons/io";
import { FiCalendar, FiLink } from "react-icons/fi";

// Utility functions/helpers
import { getReviewByIdApi } from "../../../api/reviewApi";
import { isObjectEmpty, formatDate } from "../../../helper/helper";
import { useBreadcrumb } from "../../../context/BreadcrumbContext";
import { SUMMARY_COLORS } from "../../../constants/constant";

// Static assets
import styles from "./FeedbackGuest.module.css";

// Custom components
import toaster from "../../../components/Toast/Toast";
import OverlayLoader from "../../../components/OverlayLoader";
import GuestReportDownload from "../../../components/GuestReportDownload";

const FeedbackGuest = () => {
  const { filename, guestId } = useParams();
  const { setPathDisplay } = useBreadcrumb();
  const [review, setReview] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loadinText, setLoadingText] = useState("");

  useEffect(() => {
    if (review) {
      setPathDisplay({
        [`/feedback/${filename}`]: review.document_title,
        [`/feedback/${filename}/${guestId}`]: `Guest ${guestId} Feedback`,
      });
    }
  }, [review, filename, guestId, setPathDisplay]);

  useEffect(() => {
    fetchReview();
  }, []);

  const fetchReview = async () => {
    setLoadingText("Getting Reviews...");
    setIsLoading(true);
    try {
      const response = await getReviewByIdApi(guestId);
      if (!response?.success) {
        throw response;
      }
      setReview(response.review);
    } catch (error) {
      console.error("Error fetching review:", error);
      toaster.error(
        error.message || "Failed to fetch review. Please try again later."
      );
    } finally {
      setLoadingText("");
      setIsLoading(false);
    }
  };

  const getRandomColor = useCallback(() => {
    const randomIndex = Math.floor(Math.random() * SUMMARY_COLORS.length);
    return SUMMARY_COLORS[randomIndex];
  }, []);

  if (isLoading) {
    return <OverlayLoader text={loadinText} />;
  }

  if (!review || !isObjectEmpty(review)) {
    return <p className={styles.noData}>No review found</p>;
  }

  return (
    <div className={styles.container}>
      <h2 className={styles.title}> {review.document_title} </h2>
      <p className={styles.name}>Guest {review.id} Feedback</p>
      <div className={styles.detailBox}>
        <div className={styles.item}>
          <div className={styles.label}>
            <IoMdPerson size={20} />
            <span>Reviewer</span>
          </div>
          <div className={styles.value}>Guest #{review.id}</div>
        </div>
        <div className={styles.item}>
          <div className={styles.label}>
            <FiCalendar size={20} />
            <span>Date</span>
          </div>
          <div className={styles.value}>{formatDate(review.created_at)}</div>
        </div>
        <div className={styles.item}>
          <div className={styles.label}>
            <FiLink size={20} />
            <span>Report</span>
          </div>
          {review && <GuestReportDownload document={review} />}
        </div>
      </div>
      <div className={styles.boxContainer}>
        <div className={styles.commonBox}>
          <p className={styles.heading2}>Key Takeaways</p>
          {review.misc.key_takeaways ? (
            <p className={styles.heading2Text}>{review.misc.key_takeaways}</p>
          ) : (
            <p className={styles.heading2Text}>Not Found</p>
          )}
        </div>
        <div className={styles.commonBox}>
          <p className={styles.heading2}>Detailed Feedback</p>
          {review.summary.length > 0 ? (
            review.summary.map((item, index) => (
              <div key={index} className={styles.summarySection}>
                <p
                  className={styles.heading3}
                  style={{ color: getRandomColor() }}
                >
                  {item.title}
                </p>
                <p className={styles.heading2Text}>{item.summary_text}</p>
              </div>
            ))
          ) : (
            <p className={styles.heading2Text}>Not Found</p>
          )}
        </div>
        <div className={styles.commonBox}>
          <p className={styles.heading2}>Additional Notes</p>
          {review.misc.additional_notes ? (
            <p className={styles.heading2Text}>
              {review.misc.additional_notes}
            </p>
          ) : (
            <p className={styles.heading2Text}>Not Found</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default FeedbackGuest;
