import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFDownloadLink,
} from "@react-pdf/renderer";
import IconButton from "../IconButton";
import { FaFilePdf } from "react-icons/fa6";

// Create styles
const styles = StyleSheet.create({
  page: {
    padding: 20,
    fontFamily: "Helvetica",
    fontSize: 12,
    lineHeight: 1.6,
  },
  titlePage: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  titleText: {
    fontSize: 30,
    fontWeight: "bold",
    marginBottom: 10,
  },
  subtitleText: {
    fontSize: 18,
    marginBottom: 30,
    color: "#666",
  },
  section: {
    marginBottom: 20,
  },
  heading: {
    fontSize: 18,
    marginBottom: 5,
    fontWeight: "bold",
  },
  paragraph: {
    fontSize: 12,
    color: "#333",
  },
  footer: {
    position: "absolute",
    bottom: 20,
    left: 0,
    right: 0,
    textAlign: "center",
    fontSize: 10,
    color: "#999",
  },
});

// Title Page Component
const TitlePage = () => (
  <Page size="A4" style={styles.page}>
    <View style={styles.titlePage}>
      <Text style={styles.titleText}>Client Report</Text>
      <Text style={styles.subtitleText}>
        Detailed Insights and Recommendations
      </Text>
    </View>
    <Text style={styles.footer}>Generated by hi-client.com</Text>
  </Page>
);

// Content Pages Component
const ContentPages = ({ data }) => (
  <Page size="A4" style={styles.page}>
    {data && data.length > 0 ? (
      data.map((item, index) => (
        <View key={index} style={styles.section}>
          <Text style={styles.heading}>{item.title}</Text>
          <Text style={styles.paragraph}>{item.summary_text}</Text>
        </View>
      ))
    ) : (
      <Text>No content available</Text>
    )}
    <Text style={styles.footer}>Generated by hi-client.com - Page 2</Text>
  </Page>
);

// Main Document Component
const MyDocument = ({ data }) => (
  <Document>
    <TitlePage />
    <ContentPages data={data} />
  </Document>
);

// Main App Component
const SummaryDocument = ({ data }) => (
  <div>
    {data && data.length > 0 ? (
      <PDFDownloadLink
        document={<MyDocument data={data} />}
        fileName="Client_Report.pdf"
      >
        {({ loading }) =>
          loading ? (
            "Loading..."
          ) : (
            <IconButton
              icon={FaFilePdf}
              backgroundColor="#39F2AE"
              iconColor="#fff"
              glow={true}
            />
          )
        }
      </PDFDownloadLink>
    ) : (
      <p>Loading data...</p>
    )}
  </div>
);

export default SummaryDocument;
