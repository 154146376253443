import React, { useEffect, useRef, useState } from "react";
import styles from "./ChatBox.module.css";
import CustomButton from "../../components/CustomButton";
import IconButton from "../IconButton";
import CommonBox from "../../components/CommonBox";
import QuestionNavigator from "../QuestionNavigator";
import { CHAT_TEXT } from "../../constants/constant";
import starIcon from "../../assets/icons/starIcon.svg";
import { FaArrowRightLong } from "react-icons/fa6";

import { LuMicOff, LuMic } from "react-icons/lu";

import Waveform from "../Wave/index";

const ChatBox = ({
  handleStart,
  handleNext,
  step,
  questions,
  isConnected,
  isRecording,
  setIsRecording,
  intensity,
  transcriptionLoading,
  pdfFile,
  currentQuestionIndex,
  setCurrentQuestionIndex,
}) => {
  const handlePlayPause = () => {
    setIsRecording((prevstate) => !prevstate);
  };
  return (
    <CommonBox width="30.78%">
      <div
        className={!isConnected ? styles.chatBox : styles.chatBoxMain}
        style={{ justifyContent: !pdfFile && "center" }}
      >
        {!isConnected ? (
          <>
            {pdfFile && (
              <div>
                <h2 className={styles.heading}>{CHAT_TEXT.heading}</h2>
                <hr className={styles.lineBreak} />
                <p className={styles.helperText}>{CHAT_TEXT.howWork}</p>
                <div className={styles.stepsContainer}>
                  {CHAT_TEXT.workStep.map((item) => (
                    <div key={item.step} className={styles.stepRow}>
                      <div className={styles.stepBox}>{item.step}</div>
                      <p className={styles.stepText}>{item.text}</p>
                    </div>
                  ))}
                </div>
                <hr className={styles.lineBreak} />
              </div>
            )}
            <div
              className={`${styles.startButtonBox} ${
                pdfFile ? styles.startButtonAtBottom : ""
              }`}
            >
              <CustomButton
                handleButtonClick={handleStart}
                text={CHAT_TEXT.feedbackButtonText}
                glow={true}
                backgroundColor="#39F2AE"
                textColor="white"
                height="44px"
                radius="25px" // Rounded corners
                icon={starIcon} // Icon at the start
                iconPosition="start"
                gap="12px" // Default gap between icon and text
              />
            </div>
          </>
        ) : (
          <div className={styles.chatContentBox}>
            <div className={styles.chatContent}>
              {step === 1 ? (
                <>
                  <h2 className={styles.heading}>{CHAT_TEXT.thoughtText}</h2>
                  <p className={styles.helperText}>{CHAT_TEXT.startInfo}</p>
                  <hr className={styles.lineBreak} />
                  <div className={styles.wave}>
                    {isRecording && <Waveform intensity={intensity} />}
                  </div>
                  <div className={styles.listenBox}>
                    <div
                      className={styles.circle}
                      style={{
                        backgroundColor: !isRecording ? "#A1A1A1" : "#39F2AE",
                      }}
                    />
                    <p
                      className={styles.listenText}
                      style={{ color: !isRecording ? "#A1A1A1" : "#39F2AE" }}
                    >
                      {isRecording ? "Listening" : CHAT_TEXT.micOffText}
                    </p>
                  </div>
                </>
              ) : step === 2 && questions.length > 0 ? (
                <>
                  <h2 className={styles.heading}>{CHAT_TEXT.questiontext}</h2>
                  <p className={styles.helperText}>
                    {CHAT_TEXT.questionHelperText}
                  </p>
                  <hr className={styles.lineBreak} />
                  <div className={styles.helperBox}>
                    <QuestionNavigator
                      questions={questions}
                      currentQuestionIndex={currentQuestionIndex}
                    />
                    {isRecording && <Waveform intensity={intensity} />}
                  </div>

                  {/* {questions.map((el, i) => (
                    <div key={i}>
                      <p className={styles.chatQuestions}>{el.question_text}</p>
                    </div>
                  ))} */}
                </>
              ) : (
                <></>
              )}

              {/* {transcriptionTexts.map((el, i) => (
                <div key={i}>
                  <p className={styles.chatText}>{el}</p>
                </div>
              ))} */}
            </div>
            <div className={styles.chatFooter}>
              <hr className={styles.lineBreak} />
              <p className={styles.helperText}>{CHAT_TEXT.muteText}</p>
              <div className={styles.footerBox}>
                <IconButton
                  handleButtonClick={handlePlayPause}
                  icon={!isRecording ? LuMicOff : LuMic}
                  backgroundColor={
                    !isRecording ? "rgb(93 102 115 / 25%)" : "#39F2AE"
                  } // Red background
                  iconColor="#fff" // Black icon
                  glow={true}
                  isLoading={transcriptionLoading}
                />
                <CustomButton
                  handleButtonClick={handleNext}
                  text={
                    step === 1
                      ? CHAT_TEXT.nextText
                      : currentQuestionIndex < questions.length - 1
                      ? CHAT_TEXT.nextQuestion
                      : CHAT_TEXT.finishText
                  }
                  backgroundColor="#39F2AE"
                  glow={true}
                  loading={transcriptionLoading}
                  textColor="white"
                  paddingX="35px"
                  height="48px"
                  radius="25px" // Rounded corners
                  Icon={FaArrowRightLong} // Icon at the start
                  iconPosition="end"
                  gap="12px" // Default gap between icon and text
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </CommonBox>
  );
};
export default ChatBox;
