import React from "react";
import styles from "./OverlayLoader.module.css";

const OverlayLoader = ({ text }) => {
  return (
    <div className={styles.main}>
    {' '}
    <div className={styles.box}>
      <div className={styles.loader}>
        <div className={styles.o}></div>
        <div className={styles.o}></div>
        <div className={styles.o}></div>
        <div className={styles.o}></div>
      </div>
      <div className={styles.text}>{text}</div>
    </div>{' '}

  </div>
  );
};

export default OverlayLoader;
