import styles from "./Navbar.module.css";
import logo from "../../assets/logo.svg";
import Breadcrumbs from "../Breadcrumbs";
import CustomButton from "../CustomButton";
import { NAVBAR_TEXT } from "../../constants/constant";
import { useAuth } from "../../context/AuthContext";

export function DashboardNavbar() {
  const { logout } = useAuth();
  const handleLogout = () => {
    logout();
  };
  return (
    <div className={styles.dashMain}>
      <nav className={`${styles.dashNavbar} container-95`}>
        <div className={styles.logo}>
          <img src={logo} alt="Logo" />
        </div>
        <div className={styles.breadcrumbsContainer}>
          <Breadcrumbs />
        </div>
        <div className={styles.logout}>
          <CustomButton
            handleButtonClick={handleLogout}
            text={NAVBAR_TEXT.logOutText}
            backgroundColor="#0D141C"
            textColor="white"
            minWidth="137px"
            height="50px"
            radius="25px"
            glow={false}
          />
        </div>
      </nav>
    </div>
  );
}
