// Built-in/Third-party modules
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FiUpload, FiPlus } from "react-icons/fi";

// Utility functions/helpers
import { getDocumentsApi, uploadDocumentApi } from "../../api/documentApi";
import { generateReviewLink } from "../../helper/helper";
import { FEEDBACK_TEXT } from "../../constants/constant";
import { useAuth } from "../../context/AuthContext";

// Static assets
import styles from "./Feedback.module.css";

// Custom components
import toaster from "../../components/Toast/Toast";
import SearchInput from "../../components/SearchInput";
import CardBox from "../../components/CardBox";
import FeedbackCard from "../../components/FeedbackCard";
import CustomButton from "../../components/CustomButton";
import Popup from "../../components/Popup";
import FeedbackPopupContent from "../../components/FeedbackPopupContent";
import OverlayLoader from "../../components/OverlayLoader";

const Feedback = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [searchValue, setSearchValue] = useState("");
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedPdf, setSelectedPdf] = useState(null);
  const [title, setTitle] = useState("");
  const [documents, setDocuments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingText, setLoadingText] = useState("");

  useEffect(() => {
    fetchDocuments();
  }, []);

  const fetchDocuments = async () => {
    setLoadingText("Loading your documents..."); // Fixed typo
    setIsLoading(true);
    try {
      const response = await getDocumentsApi();
      if (!response?.success) {
        throw new Error(response?.message || "Failed to fetch documents");
      }
      setDocuments(response.documents);
    } catch (error) {
      console.error("Error fetching documents:", error);
      toaster.error(
        error?.message || "Failed to fetch documents. Please try again later."
      );
    } finally {
      setLoadingText("");
      setIsLoading(false);
    }
  };

  const handleNewFeedback = () => {
    setIsPopupOpen(true);
  };

  const handleNavigation = (id) => {
    navigate(`/feedback/${id}`);
  };

  const submitFeedback = async () => {
    try {
      if (!selectedPdf) {
        throw new Error("Please select a PDF file");
      }
      if (!title?.trim()) {
        throw new Error("Please enter a title");
      }

      const formData = new FormData();
      formData.append("file", selectedPdf);
      formData.append("title", title.trim());

      setLoadingText("Uploading document...");
      setIsLoading(true);

      const response = await uploadDocumentApi(formData);
      if (!response?.success) {
        throw new Error(response?.message || "Upload failed");
      }

      toaster.success("Document uploaded successfully!");
      setSelectedPdf(null);
      setTitle("");
      setIsPopupOpen(false);
      await fetchDocuments();
    } catch (error) {
      console.error("Error uploading document:", error);
      toaster.error(
        error?.message || "Failed to upload document. Please try again."
      );
    } finally {
      setIsLoading(false);
      setLoadingText("");
    }
  };

  // Filter documents based on search
  const filteredDocuments = documents.filter((doc) =>
    doc.title.toLowerCase().includes(searchValue.toLowerCase())
  );
  const handleCopyLink = (documentId) => {
    const link = generateReviewLink(user.id, documentId);
    navigator.clipboard
      .writeText(link)
      .then(() => {
        toaster.success("Link copied to clipboard!");
      })
      .catch(() => {
        toaster.error("Failed to copy link");
      });
  };
  return (
    <div className={styles.container}>
      {isLoading && <OverlayLoader text={loadingText} />}
      <div className={styles.searchContainer}>
        <SearchInput
          placeholder="Search documents..."
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
        />
        <CustomButton
          handleButtonClick={handleNewFeedback}
          text="New Feedback"
          backgroundColor="#39F2AE"
          glow={true}
          textColor="black"
          paddingX="28px"
          height="40px"
          radius="25px" // Rounded corners
          Icon={FiUpload} // Icon at the start
          iconPosition="start"
          gap="15px" // Default gap between icon and text
        />
      </div>

      <div className={styles.cardsGrid}>
        <div className={styles.cardBoxWrapper}>
          <CardBox>
            <div className={styles.newBox} onClick={handleNewFeedback}>
              <div className={styles.iconWrapper}>
                <FiPlus size={45} />
              </div>
              <p className={styles.text}>{FEEDBACK_TEXT.addNewText} </p>
            </div>
          </CardBox>
        </div>

        {!isLoading &&
          filteredDocuments.length > 0 &&
          filteredDocuments.map((document) => (
            <div
              key={document.id}
              className={styles.cardWrapper}
              onClick={() => handleNavigation(document.id)}
            >
              <FeedbackCard
                url={document.file_url}
                title={document.title}
                reviewCount={document.review_count}
                handleCopy={(e) => {
                  e.stopPropagation();
                  handleCopyLink(document.id);
                }}
              />
            </div>
          ))}
      </div>
      <Popup
        isOpen={isPopupOpen}
        onClose={() => setIsPopupOpen(false)}
        title={FEEDBACK_TEXT.newFeedbackReqText}
      >
        <FeedbackPopupContent
          selectedPdf={selectedPdf}
          setSelectedPdf={setSelectedPdf}
          title={title}
          setTitle={setTitle}
          handleNewFeedback={submitFeedback}
          onClose={() => setIsPopupOpen(false)}
        />
      </Popup>
    </div>
  );
};

export default Feedback;
