export function float32ToPCM(float32Array) {
  const pcmArray = new Int16Array(float32Array.length);
  for (let i = 0; i < float32Array.length; i++) {
    // Convert Float32 to Int16
    pcmArray[i] = Math.max(-1, Math.min(1, float32Array[i])) * 32767; // Scale to PCM 16-bit
  }
  return pcmArray.buffer; // Return ArrayBuffer
}

export const isValidJSON = (data) => {
  try {
    JSON.parse(data);
    return true;
  } catch (error) {
    return false;
  }
};

export const calculateWordCounts = (transcript) => {
  const wordCounts = {};

  transcript.forEach(({ user, text }) => {
    const wordCount = text.trim().split(/\s+/).length; // Split text by whitespace to count words
    wordCounts[user] = (wordCounts[user] || 0) + wordCount;
  });

  return Object.entries(wordCounts).map(([user, wordCount]) => ({
    user,
    wordCount,
  }));
};

// utils/apiHelpers.js

// Helper function to concatenate text from transcription based on step
export const getTranscriptionText = (transcription, stepFilter = null) => {
  return transcription
    .filter((item) => (stepFilter !== null ? item.step === stepFilter : true))
    .map((item) => item.text)
    .join(" ");
};
export const getTranscriptionTextWithPage = (
  transcription,
  stepFilter = null
) => {
  const transcriptWithPage = transcription
    .filter((item) => (stepFilter !== null ? item.step === stepFilter : true))
    .map((item) => `Page ${item.pageNumber}: ${item.text}`) // Format to include page number
    .join(" "); // Join them with space
  return transcriptWithPage;
};
export const isObjectEmpty = (obj) => {
  return obj && Object.keys(obj).length > 0 && obj.constructor === Object;
};
export const formatDate = (dateString) => {
  const date = new Date(dateString);
  return date
    .toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    })
    .replace(",", ",");
};
// helper/helper.js
export const timeAgo = (dateString) => {
  const date = new Date(dateString);
  const now = new Date();
  const secondsAgo = Math.floor((now - date) / 1000);

  // Less than a minute
  if (secondsAgo < 60) {
    return "Just now";
  }

  // Less than an hour
  if (secondsAgo < 3600) {
    const minutes = Math.floor(secondsAgo / 60);
    return `${minutes} ${minutes === 1 ? "Min" : "Mins"} Ago`;
  }

  // Less than a day
  if (secondsAgo < 86400) {
    const hours = Math.floor(secondsAgo / 3600);
    return `${hours} ${hours === 1 ? "Hour" : "Hours"} Ago`;
  }

  // Less than a week
  if (secondsAgo < 604800) {
    const days = Math.floor(secondsAgo / 86400);
    return `${days} ${days === 1 ? "Day" : "Days"} Ago`;
  }

  // More than a week
  return date.toLocaleDateString("en-US", {
    month: "short",
    day: "numeric",
    year: "numeric",
  });
};

export const generateReviewLink = (userId, documentId) => {
  const baseUrl = window.location.origin; // Gets protocol + domain (e.g., https://example.com)
  return `${baseUrl}/?uid=${userId}&did=${documentId}`;
};
// Helper function to handle API calls with loading and error management
