import React from "react";
import styles from "./CardBox.module.css";

const CardBox = ({
  children,
  className = "",
  minHeight = "310px",
  maxWidth = "349px",
}) => {
  return (
    <div
      className={`${styles.card} ${className}`}
      style={{ minHeight, maxWidth }}
    >
      {children}
    </div>
  );
};

export default CardBox;
