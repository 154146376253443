import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { FiUpload, FiX } from "react-icons/fi";
import styles from "./DragDropPDF.module.css";
import { FEEDBACK_TEXT } from "../../constants/constant";

const DragDropPDF = ({ onFileSelect, selectedPdf }) => {
  const onDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (file && file.type === "application/pdf") {
        onFileSelect(file);
      }
    },
    [onFileSelect]
  );

  const handleDelete = (e) => {
    e.stopPropagation(); // Prevent dropzone click
    onFileSelect(null);
  };

  const getFileName = (path) => {
    if (!path) return '';
    return path.replace('./', '').split('/').pop();
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: { "application/pdf": [".pdf"] },
    maxSize: 100 * 1024 * 1024,
    multiple: false,
  });

  return (
    <div {...getRootProps()} className={styles.dropzone}>
      <input {...getInputProps()} />
      <div className={styles.iconWrapper}>
        <FiUpload size={24} />
      </div>
      
      {selectedPdf ? (
        <>
          <div className={styles.selectedFile}>
            <h3 className={styles.mainText}>{getFileName(selectedPdf.path)}</h3>
            <button onClick={handleDelete} className={styles.deleteButton}>
              <FiX size={20} />
            </button>
          </div>
        </>
      ) : (
        <>
          <h3 className={styles.mainText}>{FEEDBACK_TEXT.shareWorkText}</h3>
          <p className={styles.dragText}>{FEEDBACK_TEXT.dropText}</p>
          <p className={styles.infoText}>{FEEDBACK_TEXT.pdfText}</p>
        </>
      )}
    </div>
  );
};

export default DragDropPDF;