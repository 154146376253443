import { RiHomeLine } from "react-icons/ri";
import { Link, useLocation } from "react-router-dom";
import styles from "./Breadcrumbs.module.css";
import { routeNameMap } from "../../constants/constant";
import { useBreadcrumb } from "../../context/BreadcrumbContext";

const Breadcrumbs = () => {
  const location = useLocation();
  const { pathDisplay } = useBreadcrumb();
  const pathnames = location.pathname.split("/").filter((x) => x);

  const getRouteName = (path, fullPath) => {
    // First check if we have a custom display name
    if (fullPath && pathDisplay[fullPath]) {
      return pathDisplay[fullPath];
    }
    // Fall back to route map
    return routeNameMap[path.toLowerCase()] || path;
  };

  return (
    <nav aria-label="Breadcrumb navigation" className={styles.breadcrumbs}>
      <Link
        to="/"
        className={`${styles.breadcrumbsLink} ${styles.homeLink}`}
        aria-label="Home"
      >
        <span className={styles.icon}>
          <RiHomeLine size={20} />
        </span>
        <span>Home</span>
      </Link>

      {pathnames.map((name, index) => {
        const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`;
        const isLast = index === pathnames.length - 1;

        return (
          <span key={name} className={styles.breadcrumbsItem}>
            <span className={styles.separator} aria-hidden="true">
              /
            </span>
            {isLast ? (
              <span
                className={`${styles.breadcrumbsLink} ${styles.active}`}
                aria-current="page"
              >
                {getRouteName(name, routeTo)}
              </span>
            ) : (
              <Link to={routeTo} className={styles.breadcrumbsLink}>
                {getRouteName(name, routeTo)}
              </Link>
            )}
          </span>
        );
      })}
    </nav>
  );
};

export default Breadcrumbs;
