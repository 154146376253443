import axiosInstance from "./axiosInstance";

// Function to send Google token to the backend for verification
export const googleLoginApi = async (googleToken) => {
  try {
    const response = await axiosInstance.post("/auth/google", {
      token: googleToken,
    });
    return response.data;
  } catch (error) {
    console.error("Error with Google login:", error);
    // Return error object instead of throwing
    return {
      success: false,
      error:
        error.response?.data?.message ||
        error.message ||
        "Failed to connect to server(Google Login)",
    };
  }
};

// Function to verify JWT token with the backend
export const tokenVerifyApi = async (token) => {
  try {
    const response = await axiosInstance.post("/auth/verify", { token }); // Assuming /auth/verify endpoint for verification
    return response.data; // Response can be { success: true }
  } catch (error) {
    console.error("Error verifying token:", error);
    // Return error object instead of throwing
    return {
      success: false,
      error:
        error.response?.data?.message ||
        error.message ||
        "Failed to connect to server(Verify Token)",
    };
  }
};

export const getUserApi = async () => {
  try {
    const response = await axiosInstance.get("/auth/user");
    return response.data;
  } catch (error) {
    console.error("Error fetching user details:", error);
    return {
      success: false,
      error:
        error.response?.data?.message ||
        error.message ||
        "Failed to connect to server (Get User)",
    };
  }
};
