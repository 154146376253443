// Popup.jsx
import React from 'react';
import { FiX } from 'react-icons/fi';
import styles from './Popup.module.css';

const Popup = ({ isOpen, onClose, title, children }) => {
  if (!isOpen) return null;

  return (
    <>
      <div className={styles.overlay} onClick={onClose}>
        <div className={styles.popup} onClick={e => e.stopPropagation()}>
          <div className={styles.header}>
            {title && <h2 className={styles.title}>{title}</h2>}
            <button className={styles.closeButton} onClick={onClose}>
              <FiX size={24} />
            </button>
          </div>
          <div className={styles.content}>
            {children}
          </div>
        </div>
      </div>
    </>
  );
};

export default Popup;