import React, { useState } from "react";
import { FiUser, FiCalendar, FiMessageCircle, FiLink } from "react-icons/fi";
import { Document, Page } from "react-pdf";
import styles from "./FeedbackDetailCard.module.css";
import CustomButton from "../CustomButton";
import Toggle from "../Toggle";

const FeedbackDetailCard = ({
  title,
  imageUrl,
  ownerName,
  date,
  reviewCount,
  onCopyLink,
  handleGenerateSummary,
  shortlinkEnabled,
  onToggleShortlink,
}) => {
  const [thumbnailError, setThumbnailError] = useState(false);

  const onLoadError = () => {
    console.error("Error loading PDF");
    setThumbnailError(true);
  };
  return (
    <div className={styles.container}>
      <h1 className={styles.title}>{title}</h1>

      <div className={styles.content}>
        <div className={styles.thumbnailContainer}>
          {!thumbnailError ? (
            <Document
              file={imageUrl}
              onLoadError={onLoadError}
              className={styles.document}
            >
              <Page
                pageNumber={1}
                width={300}
                renderAnnotationLayer={false}
                renderTextLayer={false}
                className={styles.page}
              />
            </Document>
          ) : (
            <div className={styles.fallback}>
              <span>Preview not available</span>
            </div>
          )}
        </div>
        {/* <div className={styles.imageWrapper}>
          <img src={imageUrl} alt={title} className={styles.image} />
        </div> */}

        <div className={styles.details}>
          <div className={styles.row}>
            <div className={styles.item}>
              <div className={styles.label}>
                <FiUser size={20} />
                <span>Owner</span>
              </div>
              <div className={styles.value}>{ownerName}</div>
            </div>

            <div className={styles.item}>
              <div className={styles.label}>
                <FiCalendar size={20} />
                <span>Date</span>
              </div>
              <div className={styles.value}>{date}</div>
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.item}>
              <div className={styles.label}>
                <FiMessageCircle size={20} />
                <span>Review #</span>
              </div>
              <div className={styles.value}>{reviewCount}</div>
            </div>

            <div className={styles.item}>
              <div className={styles.label}>
                <FiLink size={20} />
                <span>Short Link</span>
              </div>
              <div className={styles.linkBox}>
                <Toggle
                  checked={shortlinkEnabled}
                  onChange={(checked) => onToggleShortlink(checked)}
                />
                <div className={styles.linkValue} onClick={onCopyLink}>
                  link to copy
                </div>
              </div>
            </div>
          </div>

          <div className={styles.buttonRow}>
            <CustomButton
              handleButtonClick={handleGenerateSummary}
              text="Generate Summary"
              backgroundColor="#39F2AE"
              glow={true}
              textColor="white"
              paddingX="100px"
              height="40px"
              radius="25px" // Rounded corners
              width="100%"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeedbackDetailCard;
