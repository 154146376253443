// websocketHelpers.js
import { isValidJSON } from "./helper";
import toaster from "../components/Toast/Toast";

const PING_INTERVAL = 30000; // 30 seconds
const RECONNECT_DELAY = 3000; // 3 seconds
const MAX_RECONNECT_ATTEMPTS = 3;

export const connectWebSocket = (onOpen, onMessage, onError, onClose) => {
  let reconnectAttempts = 0;
  let pingInterval;
  let reconnectTimeout;

  const connect = () => {
    const websocket = new WebSocket(
      `${process.env.REACT_APP_WEBSOCKET_URL}/ws/transcribe`
    );

    const startPingInterval = () => {
      console.log("pinging")
      if (pingInterval) clearInterval(pingInterval);
      pingInterval = setInterval(() => {
        if (websocket.readyState === WebSocket.OPEN) {
          websocket.send(JSON.stringify({ type: "ping" }));
        }
      }, PING_INTERVAL);
    };

    const stopPingInterval = () => {
      if (pingInterval) {
        clearInterval(pingInterval);
        pingInterval = null;
      }
    };

    const clearReconnectTimeout = () => {
      if (reconnectTimeout) {
        clearTimeout(reconnectTimeout);
        reconnectTimeout = null;
      }
    };

    websocket.onopen = () => {
      reconnectAttempts = 0; // Reset attempts on successful connection
      startPingInterval();
      onOpen();
    };

    websocket.onmessage = onMessage;

    websocket.onerror = (error) => {
      stopPingInterval();
      onError(error);
    };

    websocket.onclose = (event) => {
      stopPingInterval();
      clearReconnectTimeout();

      // Only attempt reconnection for abnormal closures (like code 1006)
      if (
        !event.wasClean &&
        event.code === 1006 &&
        reconnectAttempts < MAX_RECONNECT_ATTEMPTS
      ) {
        reconnectAttempts++;
        toaster.info(
          `Connection lost. Reconnecting... Attempt ${reconnectAttempts}/${MAX_RECONNECT_ATTEMPTS}`
        );

        reconnectTimeout = setTimeout(() => {
          connect(); // Recursive reconnection
        }, RECONNECT_DELAY);
      } else {
        onClose(event);
      }
    };

    return websocket;
  };

  return connect();
};

// handleMessage function remains the same

export const handleMessage = (
  event,
  setTranscription,
  setEndLoader,
  currentStep,
  pageNumber,
  setTranscriptionLoading,
  currentRequestId
) => {
  // Check if event.data is valid JSON
  if (isValidJSON(event.data)) {
    const output = JSON.parse(event.data);
    // console.log("ou",output, pageNumber)
    // Check if the response has an error field
    if (output && output.error) {
      // Handle application-specific error (sent from the server)
      toaster.error(`Error: ${output.error}`);
      console.error("Received error:", output.error);
      setTranscriptionLoading(false);
      return; // Exit early after handling the error
    }

    // Proceed with the message processing if there's no error
    if (output && output.status === "success") {
      const { requestId } = output;

      // If the message matches the current requestId, update the loading state
      if (requestId === currentRequestId) {
        setTranscriptionLoading(false); // Set loading to false when the current request is processed
      }

      switch (output.type) {
        case "transcription":
          setTranscription((prev) => [
            ...prev,
            { text: output.text, step: currentStep, pageNumber: pageNumber },
          ]);
          break;
        default:
          break;
      }
    }
  } else {
    // Handle non-JSON responses, e.g., WebSocket connection errors or plain text messages
    setEndLoader(false);
    toaster.error("Received non-JSON message");
    console.log("Received non-JSON message:", event.data);
  }
};
