// Built-in/Third-party modules
import React, { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useMicVAD } from "@ricky0123/vad-react";

// Custom components
import PdfBox from "../../components/PdfBox";
import ChatBox from "../../components/ChatBox";
import Summary from "../../components/Summary";
import OverlayLoader from "../../components/OverlayLoader";
import toaster from "../../components/Toast/Toast";

// API methods
import { submitReviewApi } from "../../api/reviewApi.js";
import { generateQuestionsApi } from "../../api/questionApi";
import { generateSummaryApi } from "../../api/summaryApi";

// Contexts
import { useAuth } from "../../context/AuthContext.js";

// Helpers
import {
  float32ToPCM,
  getTranscriptionText,
  getTranscriptionTextWithPage,
  isValidJSON,
} from "../../helper/helper";
import { callApiWithLoader } from "../../helper/helper.jsx";
import { connectWebSocket, handleMessage } from "../../helper/websocketHelpers";

// Static assets
import styles from "./Home.module.css";

const Home = () => {
  // References
  const websocketRef = useRef(null);
  const currentRequestId = useRef(null);
  const { document } = useAuth();
  const pageRef = useRef(1);
  const [searchParams] = useSearchParams();
  const userId = searchParams.get("uid");
  const documentId = searchParams.get("did");
  const [isConnected, setIsConnected] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [summary, setSummary] = useState([]);
  const [step, setStep] = useState(1);
  const stepRef = useRef(step);
  const [numPages, setNumPages] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [pdfFile, setPdfFile] = useState(null);
  const [transcription, setTranscription] = useState([]);
  const [loadinText, setLoadingText] = useState(null);
  const [endLoader, setEndLoader] = useState(false);
  // Audio & Interaction States
  const [micPermission, setMicPermission] = useState(true);
  const [intensity, setIntensity] = useState(0);
  const [transcriptionLoading, setTranscriptionLoading] = useState(false);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [isReconnecting, setIsReconnecting] = useState(false);

  const generateRequestId = () => Date.now();

  // Set up the Voice Activity Detection (VAD)
  const vad = useMicVAD({
    startOnLoad: false,
    baseAssetPath: "/static/js/",
    onnxWASMBasePath: "/static/js/",
    onSpeechStart: () => {
      console.log("User started talking");
      setTranscriptionLoading(true);
    },
    onFrameProcessed: ({ isSpeech }) => setIntensity(isSpeech),
    onSpeechEnd: (audio) => sendAudio(audio),
    onVADMisfire: () => {
      toaster.error("Speech misfire detected (too short)");
      setTranscriptionLoading(false);
    },
  });

  useEffect(() => {
    // Handle initialization
    if (vad.error) {
      console.error("VAD initialization error:", vad.error);
    }
  }, [vad.error]);
  // Sync the stepRef with the current step
  useEffect(() => {
    stepRef.current = step;
  }, [step]);
  useEffect(() => {
    pageRef.current = pageNumber;
  }, [pageNumber]);

  // Effect to manage VAD start/stop based on connection state
  useEffect(() => {
    if (isConnected && micPermission) vad.start();
    else vad.pause();
  }, [isConnected, micPermission, vad]);
  useEffect(() => {
    setTranscriptionLoading(false);
  }, [micPermission]);

  // Cleanup effect to close WebSocket connection on component unmount
  useEffect(() => {
    return () => {
      if (websocketRef.current) {
        websocketRef.current.close();
      }
    };
  }, []);

  useEffect(() => {
    if (document?.file_url) {
      setPdfFile(document.file_url);
    }
  }, [document]);

  // Function to handle connecting to the session
  const handleConnectSession = async () => {
    if (
      websocketRef.current &&
      websocketRef.current.readyState === WebSocket.OPEN
    ) {
      toaster.info("WebSocket is already connected.");
      return;
    }
    setEndLoader(true);
    setLoadingText("Connecting...");

    const websocketHandlers = {
      onOpen: () => {
        if (isReconnecting) {
          toaster.success("Reconnected successfully");
          setIsReconnecting(false);
        } else {
          toaster.success("WebSocket connected");
        }
        setIsConnected(true);
        setEndLoader(false);
      },
      onMessage: (event) => {
        if (!isValidJSON(event.data)) {
          console.error("Received invalid JSON");
          return;
        }

        const data = JSON.parse(event.data);

        // Handle ping response
        if (data.type === "pong") {
          console.log("Pong received from server"); // For debugging
          return;
        }

        const currentPage = data.pageNumber;
        handleMessage(
          event,
          setTranscription,
          setEndLoader,
          stepRef.current,
          currentPage,
          setTranscriptionLoading,
          currentRequestId.current
        );
      },
      onError: (event) => {
        console.error("WebSocket error:", event);
        setEndLoader(false);
        if (!isReconnecting) {
          toaster.error("WebSocket error occurred");
        }
      },
      onClose: (event) => {
        console.log("WebSocket closed with code:", event.code);

        // Only show disconnection message if it wasn't intentional
        if (!event.wasClean && event.code === 1006 && !isReconnecting) {
          toaster.info("Connection lost. Attempting to reconnect...");
          setIsReconnecting(true);
        } else if (event.wasClean) {
          handleReset();
        }
      },
    };

    websocketRef.current = connectWebSocket(
      websocketHandlers.onOpen,
      websocketHandlers.onMessage,
      websocketHandlers.onError,
      websocketHandlers.onClose
    );
  };

  const sendAudio = (audio) => {
    if (!isConnected || !websocketRef.current || !micPermission) {
      toaster.error("WebSocket is not connected.");
      return;
    }
    // Generate a new request ID for each audio frame
    const requestId = generateRequestId();
    currentRequestId.current = requestId; // Store the current request ID

    const pcmAudioBuffer = float32ToPCM(audio);
    const payload = {
      data: btoa(
        new Uint8Array(pcmAudioBuffer).reduce(
          (data, byte) => data + String.fromCharCode(byte),
          ""
        )
      ),
      type: "audio",
      requestId,
      pageNumber: pageRef.current,
    };
    setTranscriptionLoading(true);
    websocketRef.current.send(JSON.stringify(payload));
  };

  const disconnectWebSocket = () => {
    if (websocketRef.current) {
      websocketRef.current.close(); // Close WebSocket connection
    }
  };

  const handleStart = () => {
    if (pdfFile) {
      handleConnectSession();
    } else {
      toaster.error("Please select pdf file first");
    }
  };

  const handleNext = async () => {
    if (step === 1) {
      await handleTranscriptionStep();
    } else if (step === 2) {
      await handleQuestionsStep();
    }
  };

  const handleTranscriptionStep = async () => {
    if (!transcription.length) {
      toaster.warning("No transcription is recorded yet");
      return;
    }

    const transcriptionText = getTranscriptionText(transcription);
    await generateQuestions(transcriptionText);
  };

  const generateQuestions = async (transcriptionText) => {
    await callApiWithLoader(
      generateQuestionsApi,
      { transcription: transcriptionText },
      handleQuestionsResponse,
      "Generating questions...",
      setLoadingText,
      setEndLoader,
      setMicPermission
    );
  };

  const handleQuestionsResponse = (apiResponse) => {
    if (apiResponse.questions) {
      setQuestions(apiResponse.questions);
      setStep((prevStep) => prevStep + 1);
    } else {
      toaster.warning("Failed to generate questions.");
    }
  };

  const handleQuestionsStep = async () => {
    if (currentQuestionIndex < questions.length - 1) {
      incrementQuestionIndex();
      return;
    }

    if (isLastQuestionWithTranscription()) {
      await handleLastQuestion();
    }
  };

  const incrementQuestionIndex = () => {
    setCurrentQuestionIndex((prevState) => prevState + 1);
  };

  const isLastQuestionWithTranscription = () => {
    return (
      transcription.length > 0 && currentQuestionIndex === questions.length - 1
    );
  };

  const handleLastQuestion = async () => {
    const payload = preparePayload();
    await generateAndSubmitSummary(payload);
    setMicPermission(false);
  };

  const preparePayload = () => {
    return {
      transcription: getTranscriptionTextWithPage(transcription, 1),
      questions,
      answers: getTranscriptionText(transcription, 2),
    };
  };

  const generateAndSubmitSummary = async (payload) => {
    await callApiWithLoader(
      generateSummaryApi,
      payload,
      handleSummaryResponse,
      "Generating summary...",
      setLoadingText,
      setEndLoader,
      setMicPermission
    );
  };

  const handleSummaryResponse = async (response) => {
    if (response.summary.status !== "success") {
      toaster.error("Unable to generate summary");
      return;
    }

    if (!response.summary.feedback_summary) return;

    toaster.success("Summary generated successfully");
    updateSummaryState(response.summary);
    // Only proceed to next step if there's no document (preview mode)
    if (!documentId || !userId || !document) {
      setStep((prevStep) => prevStep + 1);
      return;
    }

    // If document exists, submit review and wait for response
    await submitReview(response.summary);
  };

  const updateSummaryState = (summary) => {
    setSummary(summary.feedback_summary);
  };

  const submitReview = async (summary) => {
    const reviewData = {
      documentId: parseInt(documentId),
      transcription: getTranscriptionTextWithPage(transcription, 1),
      summary: summary.feedback_summary,
      misc: summary.misc,
      questions: questions,
      answerText: getTranscriptionText(transcription, 2),
    };

    try {
      const response = await submitReviewApi(reviewData);
      handleReviewSubmissionResponse(response);
    } catch (error) {
      handleReviewSubmissionError(error);
    }
  };

  const handleReviewSubmissionResponse = (response) => {
    if (response.success) {
      toaster.success("Review submitted successfully");
      setStep((prevStep) => prevStep + 1);
    } else {
      toaster.error("Failed to submit review");
    }
  };

  const handleReviewSubmissionError = (error) => {
    console.error("Error submitting review:", error);
    toaster.error("Failed to submit review");
  };

  const handleDownloadPdf = () => {};

  const handleReset = () => {
    setIsConnected(false);
    setMicPermission(true);
    setTranscription([]);
    setIntensity(0);
    setEndLoader(false);
    setQuestions([]);
    setSummary([]);
    setStep(1);
    setNumPages();
    setPageNumber(1);
    setPdfFile(null);
    disconnectWebSocket();
  };

  return (
    <div className="container-95">
      {endLoader && <OverlayLoader text={loadinText} />}
      <div className={styles.main}>
        {step < 3 ? (
          <>
            <PdfBox
              pdfFile={pdfFile}
              setPdfFile={setPdfFile}
              numPages={numPages}
              setNumPages={setNumPages}
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
              isConnected={isConnected}
              isDocument={document?.file_url}
            />
            <ChatBox
              handleStart={handleStart}
              handleNext={handleNext}
              step={step}
              questions={questions}
              isConnected={isConnected}
              isRecording={micPermission}
              setIsRecording={setMicPermission}
              intensity={intensity}
              transcriptionLoading={transcriptionLoading}
              pdfFile={pdfFile}
              currentQuestionIndex={currentQuestionIndex}
              setCurrentQuestionIndex={setCurrentQuestionIndex}
            />
          </>
        ) : (
          <Summary
            summary={summary}
            handleStartOver={handleReset}
            handleDownloadPdf={handleDownloadPdf}
          />
        )}
      </div>
    </div>
  );
};
export default Home;
