import axiosInstance from "./axiosInstance";

export const generateSummaryApi = async (data) => {
  try {
    const response = await axiosInstance.post("/generate-summary", data);
    return response.data; // Expecting { status: "success", feedback_summary: [...] }
  } catch (error) {
    console.error("Error generating summary:", error);
    throw error;
    //throw error.response?.data?.detail || "Failed to join meeting";
  }
};
export const generateConsolidatedSummaryApi = async (reviews) => {
  try {
    const response = await axiosInstance.post("/consolidated-summary", {
      reviews,
    });
    return response.data;
  } catch (error) {
    console.error("Error generating consolidated summary:", error);
    throw (
      error.response?.data?.detail || "Failed to generate consolidated summary"
    );
  }
};

export const generatePdfReportApi = async (
  documentDetails,
  consolidatedSummary
) => {
  try {
    const response = await axiosInstance.post(
      "/generate-pdf",
      { documentDetails, consolidatedSummary },
      { responseType: "blob" }
    );

    // Handle PDF download
    const blob = new Blob([response.data], { type: "application/pdf" });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `${documentDetails.title}_Consolidated_Report.pdf`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);

    return { success: true };
  } catch (error) {
    console.error("Error generating PDF report:", error);
    throw error.response?.data?.detail || "Failed to generate PDF report";
  }
};
