import React from "react";
import { GoogleLogin } from "@react-oauth/google";
import RedirectIfAuthenticated from "../../providers/AuthProvider/RedirectIfAuthenticated";
import { useAuth } from "../../context/AuthContext"; // Importing useAuth to access AuthContext
import logo from "../../assets/logoLarge.svg";
import styles from "./Login.module.css";
import CommonBox from "../../components/CommonBox";
import toaster from "../../components/Toast/Toast";

function Login() {
  const { googleLogin } = useAuth(); // Using googleLogin from context to handle login

  // Handle success from Google login
  const handleSuccess = async (credentialResponse) => {
    try {
      if (!credentialResponse?.credential) {
        throw new Error("Invalid credentials received");
      }
      await googleLogin(credentialResponse.credential);
    } catch (error) {
      console.error("Error during Google login:", error);
      toaster.error("Login failed. Please try again.");
    }
  };

  // Handle failure from Google login
  const handleFailure = (error) => {
    console.error("Google login failed:", error);
    toaster.error("Google login failed. Please try again.");
  };

  return (
    <div className={`${styles.main} container-95`}>
      <div className={styles.box}>
        <div className={styles.titleBox}>
          <img src={logo} alt="logo" />
        </div>
        <CommonBox width="50%">
          <div className={styles.googleBox}>
            <GoogleLogin
              onSuccess={handleSuccess} // Success callback
              onError={handleFailure} // Failure callback
            />
          </div>
        </CommonBox>
      </div>
    </div>
  );
}

export default RedirectIfAuthenticated(Login);
