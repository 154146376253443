import styles from "./Summary.module.css";
import CommonBox from "../CommonBox";
import CustomButton from "../CustomButton";
import { SUMMARY_TEXT } from "../../constants/constant";
import starIcon from "../../assets/icons/starIcon.svg";
import SummaryDocument from "../SummaryDocument";

const Summary = ({ handleStartOver, summary }) => {
  return (
    <CommonBox width="100%">
      <div className={styles.summaryMain}>
        <div className="w-full">
          <div className={styles.top}>
            <h2 className={styles.heading}>{SUMMARY_TEXT.heading}</h2>
            <div className={styles.buttonContainer}>
              {summary.length > 0 && <SummaryDocument data={summary} />}

              <CustomButton
                handleButtonClick={handleStartOver}
                text={SUMMARY_TEXT.startText}
                backgroundColor="#39F2AE"
                glow={true}
                textColor="white"
                height="48px"
                radius="25px" // Rounded corners
                icon={starIcon} // Icon at the start
                iconPosition="start"
                gap="12px" // Default gap between icon and text
              />
            </div>
          </div>
          <div className={styles.bottom}>
            {summary.length > 0 && (
              <div className={styles.box}>
                <div className="container-95">
                  {summary.map((el, i) => (
                    <div key={i}>
                      <h3 className={styles.secondaryHeading}>{el.title}</h3>
                      <p className={styles.secondaryText}>{el.summary_text}</p>
                      {i !== summary.length - 1 && (
                        <hr className={styles.separtor} />
                      )}
                    </div>
                  ))}
                </div>
              </div>
            )}
            {/* <CommonBox>
              <div>
                <h3 className={styles.secondaryHeading}>Test</h3>
                <p className={styles.secondaryText}>Test Text</p>
              </div>
            </CommonBox> */}
          </div>
        </div>
      </div>
    </CommonBox>
  );
};

export default Summary;
