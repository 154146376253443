// src/constants.js

export const NAVBAR_TEXT = {
  welcomeMessage: "Review:",
  buttonText: "Log In",
  logOutText: "Log Out",
};
export const PDF_TEXT = {
  fileText: "Drop The File",
};
export const CHAT_TEXT = {
  feedbackButtonText: "Tell me your feedback",
  nextText: "Next",
  continueText: "Continue",
  nextQuestion: "Next question",
  finishText: "Finish",
  startInfo: "You may now start speaking to provide your feedback.",
  nextInfo: "Please click next when you are done.",
  requestText: "Please answer the following questions.",
  heading: "Ready to share your thoughts?",
  howWork: "Here's how it works:",
  thoughtText: "Share your thoughts",
  timeText: "Take your time to review and speak naturally",
  muteText:
    "Tap mute anytime to pause recording Click “Next” when you finished",
  micOffText: "Microphone is muted",
  questiontext: "Additional Questions",
  questionHelperText:
    "A few more questions to understand your perspective better",

  workStep: [
    { step: 1, text: "Hit the button below and speak naturally" },
    { step: 2, text: "You can pause anytime and navigate the document" },
    {
      step: 3,
      text: "When done, Click “Continue” and we'll ask a few follow-up questions",
    },
  ],
};

export const SUMMARY_TEXT = {
  heading: "This is Your Feedback Summary",
  startText: "Start Over",
};

export const routeNameMap = {
  dashboard: "Dashboard",
  settings: "Settings",
  profile: "Profile",
  analytics: "Analytics",
  account: "Account",
  help: "Help",
  feedback: "Feedback",
  // Add more mappings as needed
};

export const FEEDBACK_TEXT = {
  newFeedbackReqText: "New Feedback Request",
  addNewText: "Add New",
  shareWorkText: "Share Your Work",
  helperText1: "Once uploaded, you'll get a link to share with reviewers",
  uploadFileText: "Upload File",
  dropText: "Drop your PDF here or click to browse",
  pdfText: "PDF files • Up to 100MB",
};

export const SUMMARY_COLORS = ['#34A853', '#FBBC05', '#4285F4'];