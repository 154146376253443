import React from "react";
import { useNavigate } from "react-router-dom"; // Use useNavigate from react-router-dom
import { useAuth } from "../../context/AuthContext";
import OverlayLoader from "../../components/OverlayLoader";
import { routes } from "../../config/routes";

const RedirectIfAuthenticated = (WrappedComponent) => {
  const Wrapper = (props) => {
    const { isAuthenticated, loading } = useAuth();
    const navigate = useNavigate(); // Initialize useNavigate

    React.useEffect(() => {
      if (!loading && isAuthenticated) {
        navigate(routes.home, { replace: true });
      }
    }, [isAuthenticated, loading, navigate]);

    if (loading || isAuthenticated) {
      return <OverlayLoader text="Checking authentication..." />;
    }

    return <WrappedComponent {...props} />;
  };

  return Wrapper;
};

export default RedirectIfAuthenticated;
