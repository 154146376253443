import {
  RiDashboardLine,
  RiMessage2Line,
  RiSettings4Line,
  RiUser3Line,
  RiQuestionLine,
} from "react-icons/ri";
import { PATHS } from "../../routes/paths";
import { NavLink } from "react-router-dom";
import CommonBox from "../../components/CommonBox";
import styles from "./DashboardLayout.module.css";
import sidebarStyles from "./Sidebar.module.css";

const DashboardLayout = ({ children }) => {
  return (
    <>
      <aside className={sidebarStyles.sidebar}>
        <div className="container">
          <div className={sidebarStyles.mainLinks}>
            <NavLink
              to={PATHS.PROTECTED.DASHBOARD}
              className={({ isActive }) =>
                `${sidebarStyles.linkItem} ${
                  isActive ? sidebarStyles.active : ""
                }`
              }
            >
              <RiDashboardLine size={24} />
              <span>Dashboard</span>
            </NavLink>
            <NavLink
              to={PATHS.PROTECTED.FEEDBACK}
              className={({ isActive }) =>
                `${sidebarStyles.linkItem} ${
                  isActive ? sidebarStyles.active : ""
                }`
              }
            >
              <RiMessage2Line size={24} />
              <span>Feedback</span>
            </NavLink>
          </div>

          <div className={`${sidebarStyles.bottomLinks} container`}>
            <NavLink
              to={PATHS.PROTECTED.SETTINGS}
              className={sidebarStyles.bottomLink}
            >
              <RiSettings4Line size={20} />
              <span>Settings</span>
            </NavLink>
            <NavLink
              to={PATHS.PROTECTED.ACCOUNT}
              className={sidebarStyles.bottomLink}
            >
              <RiUser3Line size={20} />
              <span>Account</span>
            </NavLink>
            <NavLink
              to={PATHS.PROTECTED.HELP}
              className={sidebarStyles.bottomLink}
            >
              <RiQuestionLine size={20} />
              <span>Help</span>
            </NavLink>
          </div>
        </div>
      </aside>

      <main className={styles.container}>
        <CommonBox>{children}</CommonBox>
      </main>
    </>
  );
};

export default DashboardLayout;
