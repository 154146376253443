import { useAuth } from '../../context/AuthContext';
import { Navigate, useLocation } from 'react-router-dom';
import { PATHS } from '../../routes/paths';
import OverlayLoader from '../OverlayLoader';

const RouteGuard = ({ element: Element, layout: Layout }) => {
  const { isAuthenticated, loading } = useAuth();
  const location = useLocation();

  if (loading) {
    return <OverlayLoader />;
  }

  if (!isAuthenticated) {
    return <Navigate to={PATHS.PUBLIC.LOGIN} state={{ from: location }} replace />;
  }

  if (Layout) {
    return (
      <Layout>
        <Element />
      </Layout>
    );
  }

  return <Element />;
};

export default RouteGuard;