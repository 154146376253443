import React, { useState } from "react";
import styles from "./QuestionNavigator.module.css";

const QuestionNavigator = ({ questions, currentQuestionIndex }) => {
  return (
    <div className={styles.container}>
      {/* Question Counter */}
      <p className={styles.questionCounter}>
        Question {currentQuestionIndex + 1} of {questions.length}
      </p>

      {/* Progress Lines */}
      <div className={styles.progressContainer}>
        {questions.map((_, index) => (
          <div
            key={index}
            className={`${styles.progressLine} ${
              index <= currentQuestionIndex ? styles.activeLine : styles.inactiveLine
            }`}
          />
        ))}
      </div>

      {/* Current Question Text */}
      <p className={styles.questionText}>{questions[currentQuestionIndex].question_text}</p>
    </div>
  );
};

export default QuestionNavigator;
