import React from "react";
import styles from "./CustomButton.module.css";

// Define default properties for the button
const CustomButton = ({
  width,
  text,
  iconImage,
  Icon,
  iconPosition = "start", // default to 'start'
  gap = "8px", // default gap between icon and text
  paddingX = "16px",
  height = "40px", // default height
  radius = "4px", // default radius
  textColor = "#fff", // default text color
  backgroundColor = "#007bff", // default background color
  hoverColor,
  minWidth = "auto", // default min-width
  glow = false, // default no glow
  handleButtonClick,
  loading = false,
}) => {
  return (
    <button
      onClick={handleButtonClick}
      className={`${styles.button} ${glow ? styles.glow : ""} ${
        loading ? styles.disabled : ""
      }`}
      style={{
        height,
        borderRadius: radius,
        color: textColor,
        backgroundColor,
        gap,
        width: width ? width : "unset",
        paddingLeft: paddingX,
        paddingRight: paddingX,
        minWidth,
        boxShadow: glow && `0 0 3px 2px ${backgroundColor}`, // Apply dynamic glow
        transition: "box-shadow 0.3s ease-in-out", // Smooth transition for glow
      }}
      onMouseEnter={(e) => {
        if (!loading) {
          e.target.style.backgroundColor = hoverColor;
          e.target.style.boxShadow = `0 0 4px 2px ${hoverColor}`;
        }
      }}
      onMouseLeave={(e) => {
        if (!loading) {
          e.target.style.backgroundColor = backgroundColor;
          e.target.style.boxShadow = "none";
        }
      }}
      disabled={loading} // Disable button when loading
    >
      {iconPosition === "start" &&
        (iconImage ? <img src={iconImage} alt="icon" /> : Icon && <Icon />)}
      {!loading && <span className={styles.text}>{text}</span>}
      {iconPosition === "end" &&
        (iconImage ? <img src={iconImage} alt="icon" /> : Icon && <Icon />)}
      {loading && <div className={styles.loader}></div>}{" "}
      {/* Show loader at the end */}
    </button>
  );
};

export default CustomButton;
