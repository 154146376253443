import React from "react";
import styles from "./Waveform.module.css";

const Waveform = ({ intensity }) => {
  const barCount = 19; // Number of bars in the waveform

  // Dynamically calculate heights for each bar based on intensity
  return (
    <div className={styles.waveformContainer}>
      {Array.from({ length: barCount }).map((_, index) => (
        <div
          key={index}
          className={styles.bar}
          style={{
            height: `${Math.max(10, intensity * (Math.random() * 80 + 20))}px`, // Dynamic height based on intensity
          }}
        />
      ))}
    </div>
  );
};

export default Waveform;
