import React, { useState } from "react";
import { FiCopy, FiMessageSquare, FiTrash2 } from "react-icons/fi";
import CardBox from "../CardBox";
import { Document, Page } from "react-pdf";
import styles from "./FeedbackCard.module.css";

const FeedbackCard = ({ url, title, reviewCount, handleCopy }) => {
  const [thumbnailError, setThumbnailError] = useState(false);

  const onLoadError = () => {
    console.error("Error loading PDF");
    setThumbnailError(true);
  };

  return (
    <CardBox>
      <div className={styles.container}>
        <div className={styles.thumbnailContainer}>
          {!thumbnailError ? (
            <Document
              file={url}
              onLoadError={onLoadError}
              className={styles.document}
            >
              <Page
                pageNumber={1}
                width={300}
                renderAnnotationLayer={false}
                renderTextLayer={false}
                className={styles.page}
              />
            </Document>
          ) : (
            <div className={styles.fallback}>
              <span>Preview not available</span>
            </div>
          )}
        </div>

        <h3 className={styles.title}>{title}</h3>

        <hr className="lineBreak" />

        <div className={styles.footer}>
          <div className={styles.copyLink} onClick={handleCopy}>
            <FiCopy size={14} />
            <span>Copy Link</span>
          </div>

          <div className={styles.divider} />

          <div className={styles.reviews}>
            <FiMessageSquare size={14} />
            <span>{reviewCount} Reviews</span>
          </div>

          <div className={styles.divider} />

          <button className={styles.deleteButton}>
            <FiTrash2 size={14} />
          </button>
        </div>
      </div>
    </CardBox>
  );
};

export default FeedbackCard;
