import axiosInstance from "./axiosInstance";

export const generateQuestionsApi = async (data) => {
  try {
    const response = await axiosInstance.post("/generate-questions", data);
    return response.data; // Expecting { status: "success", follow_up_questions: [...] }
  } catch (error) {
    console.error("Error generating follow-up questions:", error);
    throw error;
  }
};
