import React from 'react';
import styles from './SearchInput.module.css';

const SearchInput = ({ 
  placeholder = "Search", 
  value, 
  onChange, 
  className = "", 

}) => {
  return (
    <div className={styles.container}>
      <svg 
        className={styles.icon} 
        xmlns="http://www.w3.org/2000/svg" 
        width="20" 
        height="20" 
        viewBox="0 0 24 24" 
        fill="none" 
        stroke="currentColor" 
        strokeWidth="2" 
        strokeLinecap="round" 
        strokeLinejoin="round"
      >
        <circle cx="11" cy="11" r="8"></circle>
        <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
      </svg>
      <input
        type="text"
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        className={`${styles.input} ${className}`}
      />
    </div>
  );
};

export default SearchInput;