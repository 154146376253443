import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { googleLoginApi, tokenVerifyApi, getUserApi } from "../api/authApi";
import { getDocumentByIdUserApi } from "../api/documentApi";
import { PATHS } from "../routes/paths";
import toaster from "../components/Toast/Toast"; // Assuming toaster is a custom component

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [document, setDocument] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [searchParams] = useSearchParams();
  const userId = searchParams.get("uid");
  const documentId = searchParams.get("did");
  const navigate = useNavigate();

  const logout = useCallback(() => {
    localStorage.removeItem("accessToken");
    setIsAuthenticated(false);
    setUser(null);
    setDocument(null);
    navigate(PATHS.PUBLIC.LOGIN, { replace: true });
  }, [navigate]);

  const fetchUserDetails = useCallback(async () => {
    try {
      const response = await getUserApi();
      if (response.success) {
        setUser(response.user);
      }
    } catch (error) {
      console.error("Error fetching user details:", error);
      toaster.error("Failed to fetch user details");
      logout();
    }
  }, [logout]);

  // Function to check if the token is valid
  const verifyToken = useCallback(
    async (token) => {
      try {
        await tokenVerifyApi(token);
        setIsAuthenticated(true);
        await fetchUserDetails();
      } catch (error) {
        toaster.error("Token expired or verification failed");
        console.error("Token verification failed:", error);
        logout();
      } finally {
        setLoading(false);
      }
    },
    [fetchUserDetails, logout]
  );

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      verifyToken(accessToken);
    } else {
      setLoading(false);
    }
  }, [verifyToken]);

  // Function to handle Google login
  const googleLogin = useCallback(
    async (googleToken) => {
      setLoading(true);
      try {
        const response = await googleLoginApi(googleToken);
        if (response.success) {
          localStorage.setItem("accessToken", response.token);
          setIsAuthenticated(true);
          setUser(response.user);
          navigate(PATHS.PROTECTED.DASHBOARD, { replace: true });
        } else {
          throw new Error(response.error || "Login failed");
        }
      } catch (error) {
        console.error("Login error:", error);
        toaster.error(error.message || "Failed to process login");
        throw error;
      } finally {
        setLoading(false);
      }
    },
    [navigate]
  );

  const fetchDocument = useCallback(async () => {
    setLoading(true);
    try {
      if (!userId || !documentId) {
        throw new Error("Missing required parameters");
      }

      const response = await getDocumentByIdUserApi(documentId, userId);
      if (!response?.success) {
        throw new Error(response?.message || "Failed to fetch document");
      }
      setDocument(response.document);
    } catch (error) {
      console.error("Error fetching document:", error);
      if (error?.message === "This document is not accessible via shortlink") {
        toaster.error("This document link has been disabled by the owner");
      } else {
        toaster.error("Failed to load document");
      }
    } finally {
      setLoading(false);
    }
  }, [userId, documentId]);

  useEffect(() => {
    if (userId && documentId) {
      fetchDocument();
    }
  }, [userId, documentId, fetchDocument]);

  const contextValue = useMemo(
    () => ({
      user,
      document,
      isAuthenticated,
      loading,
      googleLogin,
      logout,
    }),
    [user, document, isAuthenticated, loading, logout, googleLogin]
  );

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
