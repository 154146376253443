import React, { useState } from "react";
import { FiFile } from "react-icons/fi";
import styles from "./FeedbackPopupContent.module.css";
import CustomInput from "../CustomInput";
import CustomButton from "../CustomButton";
import DragDropPDF from "../DragDropPDF";
import { FiUpload, FiPlus } from "react-icons/fi";
import { FEEDBACK_TEXT } from "../../constants/constant";

const FeedbackPopupContent = ({
  handleNewFeedback,
  selectedPdf,
  setSelectedPdf,
  title,
  setTitle,
  onClose,
}) => {
  const handleFileSelect = (file) => {
    console.log("Selected file:", file);
    setSelectedPdf(file);
  };
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <FiFile size={20} />
        <span className={styles.headerText}>{FEEDBACK_TEXT.shareWorkText}</span>
      </div>

      <div className={styles.inputSection}>
        <CustomInput
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder="Enter document title"
        />
      </div>

      <div className={styles.dropzoneSection}>
        <DragDropPDF
          onFileSelect={handleFileSelect}
          selectedPdf={selectedPdf}
        />
      </div>
      <p className={styles.helperText}>{FEEDBACK_TEXT.helperText1}</p>
      <div className={styles.footer}>
        <button className={styles.cancelButton} onClick={onClose}>
          Cancel
        </button>
        <CustomButton
          handleButtonClick={handleNewFeedback}
          text="New Feedback"
          backgroundColor="#39F2AE"
          glow={true}
          textColor="black"
          paddingX="28px"
          height="40px"
          radius="25px" // Rounded corners
          Icon={FiUpload} // Icon at the start
          iconPosition="start"
          gap="15px" // Default gap between icon and text
        />
      </div>
    </div>
  );
};
export default FeedbackPopupContent;
