// src/components/common/Toggle/Toggle.jsx
import React from 'react';
import styles from './Toggle.module.css';

const Toggle = ({ checked, onChange, disabled = false }) => {
  const handleChange = (e) => {
    // Pass only the checked value to the parent
    onChange(e.target.checked);
  };

  return (
    <label className={styles.toggleSwitch}>
      <input
        type="checkbox"
        checked={checked}
        onChange={handleChange}
        disabled={disabled}
      />
      <span className={styles.slider}></span>
    </label>
  );
};

export default Toggle;