import React, { useState } from "react";
import styles from "./PdfBox.module.css";
import CommonBox from "../CommonBox";
import fileIcon from "../../assets/icons/fileIcon.svg";
import { PDF_TEXT } from "../../constants/constant";
import { Document, Page } from "react-pdf";
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from "react-icons/md";
import { ImCross } from "react-icons/im";

const PdfBox = ({
  numPages,
  setNumPages,
  pageNumber,
  setPageNumber,
  pdfFile,
  setPdfFile,
  isConnected,
  isDocument,
}) => {
  const [loading, setLoading] = useState(true);
  const [pageWidth, setPageWidth] = useState(0);
  function onDocumentLoadSuccess({ numPages: nextNumPages }) {
    setNumPages(nextNumPages);
  }

  function onPageLoadSuccess(event) {
    const width = window.innerWidth;
    setPageWidth(width); // Use the actual width of the PDF page
    setLoading(false);
  }

  const options = {
    standardFontDataUrl: "standard_fonts/",
  };

  // Handle file selection
  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      setPdfFile(file); // Set the selected PDF file
    }
  };

  // Handle drag and drop
  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file) {
      setPdfFile(file); // Set the dropped PDF file
    }
  };

  // Handle drag over event (to allow drop)
  const handleDragOver = (event) => {
    event.preventDefault();
  };

  // Remove PDF file
  const removePdfFile = () => {
    setPdfFile(null); // Clear the PDF file state
    setNumPages(null); // Reset number of pages
    setPageNumber(1); // Reset page number
  };

  const handleNext = () => {
    if (pageNumber < numPages) {
      setPageNumber((prevState) => prevState + 1);
    }
  };

  // Navigate to the previous page
  const handlePrevious = () => {
    if (pageNumber > 1) {
      setPageNumber((prevState) => prevState - 1);
    }
  };

  return (
    <CommonBox width="69.22%" paddingTop="0" paddingBottom="0">
      {pdfFile && (
        <div className={styles.pageControlMain}>
          <div className={styles.pageControl}>
            <div className={styles.pageControlBox} onClick={handlePrevious}>
              <MdKeyboardArrowLeft />
            </div>
            <p className={styles.pageNumberText}>
              Page {pageNumber} of {numPages}
            </p>
            <div className={styles.pageControlBox} onClick={handleNext}>
              <MdKeyboardArrowRight />
            </div>
          </div>
          {(!isConnected && !isDocument) && (
            <div className={styles.crossIcon} onClick={removePdfFile}>
              <ImCross />
            </div>
          )}
        </div>
      )}
      <div className={!pdfFile ? styles.pdfBox : styles.pdfFileBox}>
        {!pdfFile ? (
          <div
            className={styles.iconBox}
            onClick={() => document.getElementById("fileInput").click()}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
          >
            <input
              type="file"
              id="fileInput"
              style={{ display: "none" }}
              accept="application/pdf"
              onChange={handleFileSelect}
            />
            <div>
              <img src={fileIcon} alt="file" />
              <p className={styles.fileText}>{PDF_TEXT.fileText}</p>
            </div>
          </div>
        ) : (
          <div className={styles.pdfViewer}>
            <Document
              file={pdfFile}
              onLoadSuccess={onDocumentLoadSuccess}
              //   options={options}
              renderMode="canvas"
              //   className="w-full"
            >
              <Page
                pageNumber={pageNumber}
                className={styles.pageContainer}
                renderAnnotationLayer={false}
                renderTextLayer={false}
                onLoadSuccess={onPageLoadSuccess}
                onRenderError={() => {
                  setLoading(false);
                  console.error("Error rendering page");
                }}
                width={Math.max(pageWidth * 0.61, 390)}
              />
            </Document>
          </div>
        )}
      </div>
    </CommonBox>
  );
};

export default PdfBox;
