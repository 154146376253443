import axiosInstance from "./axiosInstance";

export const submitReviewApi = async (data) => {
  try {
    const response = await axiosInstance.post("/reviews", data);
    return response.data;
  } catch (error) {
    console.error("Error submitting review:", error);
    throw error;
  }
};

export const getDocumentReviewsApi = async (documentId) => {
  try {
    const response = await axiosInstance.get(`/reviews/document/${documentId}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching document reviews:", error);
    throw error;
  }
};

export const getReviewByIdApi = async (reviewId) => {
  try {
    const response = await axiosInstance.get(`/reviews/${reviewId}`);
    return response.data;
  } catch (error) {
    throw (
      error.response?.data || {
        success: false,
        message: "Failed to fetch review",
      }
    );
  }
};
